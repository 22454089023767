import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Action, State, Store, type StateContext } from '@ngxs/store';
import { first, map, mergeMap, tap } from 'rxjs';

import {
  createNavigateToAssetNotFoundPageAction,
  CustomerPortalAssetName,
} from '@customer-portal/common/ui-asset-not-found';
import {
  CustomerPortalProjectDetailsActions,
  CustomerPortalProjectQueries,
} from '@customer-portal/projects/data-access/store';
import { CustomerPortalQuotesApiService } from '@customer-portal/quotes/data-access/api';
import type { SearchCriteria } from '@esp/common/types';
import type { OrderSearch } from '@esp/orders/types';

import { CustomerPortalQuotesSearchActions } from '../actions';

export interface CustomerPortalQuotesSearchStateModel {
  total: number;
  criteria: SearchCriteria | null;
  quotes: OrderSearch[] | null;
}

type ThisStateContext = StateContext<CustomerPortalQuotesSearchStateModel>;

const MAX_SUPPORTED_PROJECTS_SEARCH_RESULT_COUNT = 1000;

@State<CustomerPortalQuotesSearchStateModel>({
  name: 'quotesSearch',
  defaults: {
    total: 0,
    criteria: {
      from: 1,
      size: 50,
    },
    quotes: null,
  },
})
@Injectable()
export class CustomerPortalQuotesSearchState {
  constructor(
    private readonly _store: Store,
    private readonly _quotesApiService: CustomerPortalQuotesApiService
  ) {}

  @Action(CustomerPortalQuotesSearchActions.Search, { cancelUncompleted: true })
  search(
    ctx: ThisStateContext,
    action: CustomerPortalQuotesSearchActions.Search
  ) {
    ctx.patchState({ quotes: null });

    // We should wait until the project is loaded since the `Search` might be
    // dispatched meanwhile the project is still loading.
    const projectId$ = this._store
      .select(CustomerPortalProjectQueries.getProjectId)
      .pipe(first((projectId): projectId is number => projectId != null));

    return projectId$.pipe(
      mergeMap((projectId) =>
        this._quotesApiService
          .getCustomerVisibleQuotes(projectId, action.criteria)
          .pipe(map((response) => ({ projectId, response })))
      ),
      tap(({ projectId, response }) => {
        const quotes = response?.Results || [];

        // If here is only 1 quote available then we should load the individual quote page.
        // We have to check for `ResultsTotal` and not for `Results.length` because the user might
        // be navigating between pages when paginating.
        if (response.ResultsTotal === 0) {
          ctx.dispatch([
            // recalculate the header values
            new CustomerPortalProjectDetailsActions.GetProjectDetails(
              projectId
            ),
            createNavigateToAssetNotFoundPageAction(
              projectId,
              CustomerPortalAssetName.Quote
            ),
          ]);
        } else if (response.ResultsTotal === 1) {
          ctx.dispatch(
            new Navigate([`/projects/${projectId}/quotes/${quotes[0].Id}`])
          );
        } else {
          ctx.patchState({
            quotes,
            criteria: action.criteria,
            total: Math.min(
              response?.ResultsTotal ?? 0,
              MAX_SUPPORTED_PROJECTS_SEARCH_RESULT_COUNT
            ),
          });
        }
      })
    );
  }

  @Action(CustomerPortalQuotesSearchActions.Prune)
  prune(ctx: ThisStateContext) {
    ctx.patchState({ quotes: null });
  }
}
