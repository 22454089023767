import type { SearchCriteria } from '@esp/common/types';

const ACTION_SCOPE = '[Quotes Search]';

export namespace CustomerPortalQuotesSearchActions {
  export class Search {
    static readonly type = `${ACTION_SCOPE} Search`;

    constructor(readonly criteria: SearchCriteria) {}
  }

  export class Prune {
    static readonly type = `${ACTION_SCOPE} Prune`;
  }
}
